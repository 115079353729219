<template>
  <div class="register-root-container">
    <div
      v-if="currentIndex == 0"
      style="
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        max-width: 30%;
      "
    >
      <logo style="align-self: center" />
      <div
        style="
          font-family: AppleColorEmoji;
          font-size: 26px;
          color: #3e3e3e;
          align-self: center;
        "
      >
        😊首先,您需要使用邮箱进行注册
      </div>
      <my-input
        ref="formEmail"
        v-model="email"
        placeholder="输入您的邮箱信息"
        :title="title"
        size="large"
        style="margin-top: 100px; width: 100%"
        :validate="emailValidate"
        @keyup.enter.native="next"
      />
      <span style="font-size: 15px; color: #585858"
        >点击下一步时会自动向该邮箱地址发送验证链接</span
      >
      <Button
        type="primary"
        size="large"
        long
        style="margin-top: 40px; margin-bottom: 10px"
        @click="next"
        >下一步</Button
      >
      <div>
        已有账户?
        <span style="margin-left: 15px; cursor: pointer" @click="login"
          >去登录</span
        >
      </div>
    </div>
    <div
      v-if="currentIndex == 1"
      style="
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        max-width: 30%;
      "
    >
      <logo style="align-self: center" />
      <div
        style="
          font-size: 32px;
          font-weight: bold;
          color: #000000;
          align-self: center;
          margin-top: 50px;
          margin-bottom: 90px;
        "
      >
        就快要完成了！
      </div>
      <div
        style="
          align-self: center;
          font-size: 23px;
          color: #373737;
          background: #fff9ee;
          border-radius: 8px;
          width: 690px;
          height: 140px;
          padding: 35px 75px 50px 70px;
          text-align: center;
        "
      >
        我们向您的邮箱({{ email }})发送了验证邮件,请点击后完成设置
      </div>
      <div
        style="
          font-size: 18px;
          color: #606060;
          margin-top: 35px;
          align-self: center;
        "
      >
        如果您看不到邮件,请检查您的垃圾邮件文件夹
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import Logo from "../../components/logo";
import MyInput from "../../components/Form/MyInput";
export default {
  components: {
    logo: Logo,
    "my-input": MyInput,
  },
  data() {
    return {
      title: "",
      email: "",
      currentIndex: 0,
    };
  },
  methods: {
    ...mapActions({
      logoutAction: "logout",
      sendRegistMailAction: "sendRegistMail",
    }),
    emailValidate(value) {
      return new Promise((resolve, reject) => {
        if (this.email == "") {
          reject("邮箱不能为空");
          return;
        }
        if (
          !/^([a-zA-Z\d])(\w|\-)+@[a-zA-Z\d]+\.[a-zA-Z]{2,4}$/.test(this.email)
        ) {
          reject("请输入正确的邮箱");
          return;
        }
        resolve();
      });
    },
    login() {
      this.logoutAction();
      this.$router.push({ name: "login" });
    },
    next() {
      this.$refs.formEmail
        .doValidate()
        .then((res) => {
          this.sendRegistMailAction({
            email: this.email,
          })
            .then((res) => {
              this.currentIndex += 1;
            })
            .catch((error) => {
              this.$Message.error(error);
            });
        })
        .catch((error) => {
          this.$Message.warning(error);
        });
    },
  },
};
</script>

<style scoped>
.register-root-container {
  display: flex;
  flex-direction: column;
  padding-top: 140px;
}
</style>